<template>
  <div class="auth-page">
    <div class="container">
      <v-card class="mx-auto pa-10 mt-4 mt-lg-15" max-width="496">
        <ErrorBox :error="detailError" />
        <LoginForm
          :pServerErr="serverErrors"
          v-on:loginformok="login($event)"
          v-on:loginformchanged="serverErrors = null"
        />
      </v-card>
    </div>
    <Spinner :pOverlay="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginForm from "@/components/common/LoginForm";
import ErrorBox from "@/components/layout/ErrorBox";
import Spinner from "@/components/layout/Spinner";
import { OPERATOR_LOGIN } from "@/store/actions.type";

export default {
  name: "Login",
  components: { ErrorBox, LoginForm, Spinner },
  data() {
    return {
      isLoading: false,
      detailError: { message: "", title: "" },
      serverErrors: null
    };
  },
  computed: {
    ...mapGetters(["ifOperatorAuthenticated"])
  },
  methods: {
    login(formData) {
      this.isLoading = true;
      this.$store
        .dispatch(OPERATOR_LOGIN, {
          username: formData.user,
          password: formData.pwd
        })
        .then(() => this.$router.push({ name: "operator-dashboard" }))
        .catch(error => {
          this.isLoading = false;
          if (error == null || error.response == null) {
            this.detailError = {
              title: "Servizio non disponibile",
              message: "Riprovare login"
            };
            return;
          }
          switch (error.response.status) {
            case 400:
              this.detailError = {
                title: "Errore",
                message: "Problema occorso durante il tentativo di accesso."
              };
              document.getElementById("boxErrCmp").focus();
              break;
            case 401:
              this.serverErrors = "Utente o password errati.";
              break;
            case 500:
              this.detailError = {
                title: "Servizio non disponibile",
                message: "Riprovare login"
              };
              document.getElementById("boxErrCmp").focus();
              break;
          }
        });
    },
    resetErroriServer() {
      this.detailError = { message: "", title: "" };
    }
  },
  mounted() {
    if (this.ifOperatorAuthenticated)
      this.$router.push({ name: "operator-dashboard" });
  }
};
</script>
