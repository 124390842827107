<template>
  <div class="login-card">
    <v-row align="center" justify="center" class="mb-5">
      <TenantLogo :pMaxWidth="104" />
    </v-row>
    <v-row align="center" justify="center">
      <v-card-title>
        <v-img
          :src="require('@/assets/images/vide.svg')"
          alt="VIDE, Your Virtual Desk"
          max-width="194.58"
          fluid
        />
      </v-card-title>
    </v-row>
    <v-card-subtitle class="text-center mb-6">
      Accedi con le tue credenziali
    </v-card-subtitle>
    <v-card-text>
      <v-form @submit.prevent="login" id="login-form">
        <v-text-field
          clearable
          clear-icon="mdi-close-circle"
          label="Username"
          id="user"
          type="text"
          v-model="loginForm.user"
          :maxLength="$v.loginForm.user.$params.maxLength.max"
          :error-messages="userErrors"
          autocomplete="off"
          :error-count="4"
          @change.native="resetServerError()"
        ></v-text-field>
        <v-text-field
          clearable
          clear-icon="mdi-close-circle"
          label="Password"
          id="pwd"
          type="password"
          v-model="loginForm.pwd"
          :maxLength="$v.loginForm.pwd.$params.maxLength.max"
          :error-messages="pwdErrors"
          autocomplete="off"
          :error-count="4"
          @change.native="resetServerError()"
        ></v-text-field>
        <v-btn type="submit" block class="loginBtnForm btn--Large">
          ACCEDI
        </v-btn>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import { USERNAME_MAX_LENGTH, PASSWORD_MAX_LENGTH } from "@/common/config";
import TenantLogo from "@/components/layout/TenantLogo";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  components: {
    TenantLogo
  },
  props: {
    pServerErr: { type: String, required: false }
  },
  data() {
    return {
      loginForm: {
        user: null,
        pwd: null
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    loginForm: {
      user: {
        required,
        maxLength: maxLength(USERNAME_MAX_LENGTH),
        serverValidOk: function() {
          return this.pServerErr == null;
        }
      },
      pwd: {
        required,
        maxLength: maxLength(PASSWORD_MAX_LENGTH),
        serverValidOk: function() {
          return this.pServerErr == null;
        }
      }
    }
  },
  computed: {
    userErrors() {
      const errors = [];
      if (!this.$v.loginForm.user.$dirty) return errors;
      !this.$v.loginForm.user.required &&
        errors.push("Lo username è obbligatorio.");
      !this.$v.loginForm.user.maxLength &&
        errors.push(
          "Lo username deve avere una lunghezza massima di " +
            this.$v.loginForm.user.$params.maxLength.max +
            " caratteri."
        );
      !this.$v.loginForm.user.serverValidOk && errors.push(this.pServerErr);
      return errors;
    },
    pwdErrors() {
      const errors = [];
      if (!this.$v.loginForm.pwd.$dirty) return errors;
      !this.$v.loginForm.pwd.required &&
        errors.push("La password è obbligatoria.");
      !this.$v.loginForm.pwd.maxLength &&
        errors.push(
          "La password deve avere una lunghezza massima di " +
            this.$v.loginForm.pwd.$params.maxLength.max +
            " caratteri."
        );
      !this.$v.loginForm.pwd.serverValidOk && errors.push(this.pServerErr);
      return errors;
    }
  },
  methods: {
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("loginformok", {
        user: this.loginForm.user,
        pwd: this.loginForm.pwd
      });
    },
    resetServerError() {
      this.$emit("loginformchanged");
    }
  }
};
</script>
